export const STATUSES = [
  { value: 0, label: 'Không hoạt động' },
  { value: 1, label: 'Hoạt động' },
]
export const PROGRAMME_STATUSES = [
  { value: 0, label: 'Dự thảo' },
  { value: 1, label: 'Đang áp dụng' },
]
export const PROGRAMME_SUBJECT_TYPE = [
  { value: 0, label: 'HP bắt buộc' },
  { value: 1, label: 'HP tự chọn' },
]
export const COUNT_4_GPA_TYPE = [
  { value: 0, label: '&#10060;' },
  { value: 1, label: '&#9989;' },
]
export const STATUS_SYMBOLS = [
  { value: 0, label: '&#10008;' },
  { value: 1, label: '&#10004;' },
]

export const CREDIT_CLASS_STATUSES = [
  { value: -1, label: 'Hủy lớp' },
  { value: 0, label: 'Không mở' },
  { value: 1, label: 'Đang đăng ký' },
  { value: 2, label: 'Đăng ký mở rộng' },
  { value: 3, label: 'Đang học' },
  { value: 4, label: 'Kết thúc' },
]
export const CREDIT_CLASS_TEACHER_STATUSES = [
  { value: 0, label: 'Ngưng dạy' },
  { value: 1, label: 'Đang dạy' },
]
export const CREDIT_CLASS_STUDENT_STATUSES = [
  { value: -2, label: 'Không hoàn thành' },
  { value: -1, label: 'Rút đăng ký' },
  { value: 0, label: 'Đã đăng ký' },
  { value: 1, label: 'Đang học' },
  { value: 2, label: 'Đã hoàn thành' },
]

export const GENDERS = [
  { value: 0, label: 'Không xác định' },
  { value: 1, label: 'Nam' },
  { value: 2, label: 'Nữ' },
]
export const COURSE_SEMESTER_STATUSES = [
  { value: 0, label: 'Chưa học' },
  { value: 1, label: 'Đang học' },
  { value: 2, label: 'Đã kết thúc' },
]
export const STUDENT_CLASS_STATUSES = [
  { value: -2, label: 'Đình chỉ' },
  { value: -1, label: 'Thôi học' },
  { value: 0, label: 'Bảo lưu' },
  { value: 1, label: 'Đang học' },
  { value: 2, label: 'Đã tốt nghiệp' },
]

export const ELIGIBLE_STATUSES = [
  { value: 0, label: 'Không' },
  { value: 1, label: 'Có' },
]

export const DISCIPLINE_LEVEL = [
  { value: 1, label: 'Cấp khoa' },
  { value: 2, label: 'Cấp trường' },
  { value: 3, label: 'Cấp bộ' },
  { value: 4, label: 'Cấp nhà nước' },
  { value: 5, label: 'Cấp tỉnh' },
  { value: 6, label: 'Cấp ngành' },
]
export const ROOM_TYPES = [
  { value: 1, label: 'Phòng học lý thuyết' },
  { value: 2, label: 'Phòng máy tính' },
  { value: 3, label: 'Phòng thí nghiệm' },
]

export const YEARS = [
  { value: 1, label: 'Năm thứ nhất' },
  { value: 2, label: 'Năm thứ hai' },
  { value: 3, label: 'Năm thứ ba' },
  { value: 4, label: 'Năm thứ tư' },
]

export const SCORE_LOCKS = [
  { value: 0, text: 'Mở khóa' },
  { value: 1, text: 'Khóa' },
]

export const PAYMENT_STATUSES = [
  { value: 0, label: 'Chưa đóng' },
  { value: 1, label: 'Đã đóng' },
]

export const GRADUATION_TYPE = [
  { value: 1, label: 'Làm đồ án, khóa luận tốt nghiệp' },
  { value: 3, label: 'Học phần chuyên môn thay thế' },
]

export const DIPLOMA_STATUSES = [
  { value: -1, label: 'Không duyệt' },
  { value: 0, label: 'Chờ duyệt' },
  { value: 1, label: 'Đã duyệt' },
  { value: 2, label: 'Đã đóng' },
]

export const CATEGORY_LOGS = [
  { value: 'LOGIN', label: 'Đăng nhập hệ thống' },
  { value: 'UPDATE_RECEIPT', label: 'Cập nhật phiếu thu' },
  { value: 'CREATE_ACCOUNT_ROLES', label: 'Tạo tài khoản' },
  { value: 'CHANGE_ACCOUNT_ROLES', label: 'Thay đổi quyền tài khoản' },
  { value: 'RESET_PASSWORD', label: 'Đặt lại mật khẩu' },
  { value: 'CHANGE_PASSWORD', label: 'Thay đổi mật khẩu' },
  { value: 'DELETE_CREDIT_CLASS', label: 'Xóa lớp độc lập' },
  { value: 'UPDATE_CREDIT_CLASS_EXAMINATION_SCORE_LOCKED', label: 'Khóa/mở nhập điểm thi theo học phần' },
  { value: 'CHANGE_STATUS_CREDIT_CLASS_STUDENT', label: 'Thay đổi trạng thái sinh viên trong lớp độc lập' },
  { value: 'DIPLOMA_REVIEWS', label: 'Đăng ký song ngành' },
  { value: 'UPDATE_SCORE_LOCKED', label: 'Khóa/mở nhập điểm theo lớp' },
  { value: 'REGISTER_SUBJECT', label: 'Đăng ký học phần' },
  { value: 'SUSPENDED_STUDENT', label: 'Buộc thôi học' },
  { value: 'CHANGE_INFO_STUDENT_CLASS', label: 'Thay đổi thông tin sinh viên' },
  { value: 'ENTER_POINT_EXAMINATION_ROOM', label: 'Nhập điểm thi' },
]
export const FILTER_REGISTER_SUBJECT = [
  { value: '0', label: 'Học phần tự chọn' },
  { value: '1', label: ' Học phần thay thế KLTN' },
]
// Cảm tình đảng
export const HAS_LEARN_PARTY = [
  { value: 0, label: 'Chưa học' },
  { value: 1, label: 'Đã học' },
]
