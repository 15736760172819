import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async getListOfPublications() {
      try {
        const response = await axios.get('uni/digital-libraries/get-list-of-publications')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data.data
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getListOfBorrowing() {
      try {
        const response = await axios.get('uni/digital-libraries/get-list-of-borrowing')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getInfoPublication({commit}, params) {
      try {
        const response = await axios.get('uni/digital-libraries/get-info-publications', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data.data
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async registerBorrowing({commit}, body) {
      try {
        const response = await axios.post('uni/digital-libraries/register-borrowing', body)
        const { data } = response
        return data.code === ApiCode.SUCCESS
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
